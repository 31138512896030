<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
    >
      <b-container>
        <div class="text-center" v-if="isLoading">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <div class="oderDetailContent">
            <b-card class="section-body">
              <div class="ticketItemContent">
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#order_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Order</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'order_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="transaction-info">
                          <div class="transaction-info-row d-flex">
                            <div class="transaction-info-item f-1">
                              <div class="item-title">User Name</div>
                              <div class="item-value">
                                {{ viewItems.order.account ? viewItems.order.account.username : '' }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Status</div>
                              <div
                                class="item-value"
                                :class="'text-' + statusList.find((status) => status.value == viewItems.status).variant"
                              >
                                {{ statusList.find((status) => status.value == viewItems.status).text }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Receiver Name</div>
                              <div class="item-value">
                                {{ viewItems.order.receiver_name }}
                              </div>
                            </div>
                          </div>
                          <div class="transaction-info-row d-flex">
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Receiver Phone</div>
                              <div class="item-value">
                                {{ viewItems.order.receiver_phone }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Shipping Method</div>
                              <div class="item-value">
                                {{ viewItems.order.shipping_method.name }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Payment Method</div>
                              <div class="item-value">
                                {{ viewItems.order.payment_method.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#detail_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Order Detail</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'detail_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div
                          class="receiver-info d-flex"
                          v-for="(order, index) in viewItems.order_details"
                          :key="index"
                        >
                          <div class="ticket-item w-100">
                            <div class="ticket-detail">
                              <div class="ticket-name">
                                {{ order.ticket_name }}
                              </div>
                              <div class="product-name pr-3">
                                {{ order.period_name }}
                              </div>
                              <div class="product-name">
                                {{ viewItems.game.name }}
                              </div>
                              <span class="ticket-price float-right correct"
                                >{{ formatPrice(order.price) }}&nbsp;<img style="position: relative; top: -2px"
                              /></span>
                              <span class="ticket-price float-right correct"
                                >{{ order.product.name + ' | ' }}&nbsp;<img style="position: relative; top: -2px"
                              /></span>
                            </div>
                            <div
                              class="ticket-result d-flex flex-wrap"
                              v-if="!ticketStatus.includes(order.ticket_info)"
                            >
                              <div
                                :class="{
                                  ticketItemListNumber: true
                                }"
                                v-for="(number, index) in JSON.parse(order.ticket_info)"
                                :key="index"
                              >
                                {{ number }}
                              </div>
                            </div>
                            <div class="ticket-result d-flex flex-wrap" v-else>
                              <div>
                                {{ order.ticket_info }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#operation_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Operation Logs</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'operation_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="kt-timeline-v2">
                          <div class="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                            <perfect-scrollbar style="max-height: 100vh; position: relative">
                              <template v-for="(item, i) in opcLogs">
                                <div class="kt-timeline-v2__item" :key="`item-${i}`">
                                  <span class="kt-timeline-v2__item-time">{{ item.time }}</span>
                                  <div class="kt-timeline-v2__item-cricle">
                                    <i v-bind:class="item.icon"></i>
                                  </div>
                                  <div
                                    class="kt-timeline-v2__item-text kt-padding-top-5"
                                    v-bind:class="{
                                      'kt-timeline-v2__item-text--bold': item.bold
                                    }"
                                    v-html="item.text"
                                  ></div>
                                  <div
                                    :key="`images-${i}`"
                                    v-if="item.images"
                                    class="kt-list-pics kt-list-pics--sm kt-padding-l-20"
                                  >
                                    <template v-for="(item, i) in item.images">
                                      <a href="#" :key="i" v-html="item"></a>
                                    </template>
                                  </div>
                                </div>
                              </template>
                            </perfect-scrollbar>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div class="accordion" v-if="!viewItems.account_id || viewItems.order.mail">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#mail_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Mail Logs</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'mail_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <b-row>
                          <b-col cols="8">
                            <b-form-group class="w-100">
                              <label for="email">Email: </label>
                              <b-form-input
                                id="email"
                                v-model="viewItems.order.mail"
                                name="email"
                                data-vv-as="Email"
                                type="email"
                                :class="{
                                  'is-danger': errors.has('email')
                                }"
                                v-validate="{ required: true, email: true }"
                              />
                              <span v-show="errors.has('email')" class="is-danger-text position-relative">{{
                                errors.first('email')
                              }}</span>
                            </b-form-group>
                          </b-col>
                          <b-col cols="4">
                            <b-button
                              style="margin-top: 1.95rem"
                              block
                              variant="primary"
                              small
                              @click="updateTicketMail"
                              >Update</b-button
                            >
                          </b-col>
                        </b-row>
                        <div class="kt-timeline-v2">
                          <div class="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                            <perfect-scrollbar style="max-height: 100vh; position: relative">
                              <template v-for="(item, i) in mailLogs">
                                <div class="kt-timeline-v2__item" :key="`item-${i}`">
                                  <span class="kt-timeline-v2__item-time">{{ item.time }}</span>
                                  <div class="kt-timeline-v2__item-cricle">
                                    <i v-bind:class="item.icon"></i>
                                  </div>
                                  <div
                                    class="kt-timeline-v2__item-text kt-padding-top-5"
                                    v-bind:class="{
                                      'kt-timeline-v2__item-text--bold': item.bold
                                    }"
                                    v-html="item.text"
                                  ></div>
                                  <v-icon
                                    :id="'resend_' + i"
                                    class="text-primary ml-3 mt-1"
                                    @click="resendTicketMail(item.mail_type)"
                                    >mdi-send</v-icon
                                  >
                                  <b-tooltip :target="'resend_' + i" triggers="hover">Resend</b-tooltip>
                                </div>
                              </template>
                            </perfect-scrollbar>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </b-container>
    </b-modal>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="cancelDialog"
      size="md"
      title="Ticket Cancel"
      ok-title="Done"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="cancelTicket"
      @cancel="closeCancelDialog"
    >
      <b-container class="w-100">
        <b-row class="reason-form">
          <b-form-group class="w-100">
            <label for="cancelReason">Reason: </label>
            <input
              id="cancelReason"
              v-model="cancelReason"
              name="cancelReason"
              data-vv-as="Reason"
              :class="{
                input: true,
                'is-danger': errors.has('cancelReason')
              }"
              v-validate="{ required: true }"
            />
            <span v-show="errors.has('cancelReason')" class="is-danger-text position-relative">{{
              errors.first('cancelReason')
            }}</span>
          </b-form-group>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Danh sách vé</h4>
      </b-col>
      <b-col cols="9">
        <div class="h-100 d-flex align-items-center">
          <b-form-checkbox size="lg" id="checkbox" v-model="filterEmptyBackImage" name="checkbox">
            Lọc vé chưa có hình
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="3">
        <b-button
          small
          block
          :variant="isAutoRefresh ? 'success' : 'info'"
          class="mb-3 text-uppercase text-white"
          @click="toggleAutoRefresh"
        >
          {{ isAutoRefresh ? 'Auto on' : 'Auto off' }}
        </b-button>
      </b-col>
      <b-col cols="12">
        Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        trong {{ rows }}.
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(priority)="item">
        {{ item.value || 0 }}
      </template>

      <template v-slot:cell(lottery_time)="item">
        {{ formatTime(item.value, 'HH:mm:ss') }}
      </template>
      <template v-slot:cell(data)="item">
        <span v-for="[index, data] of item.value.entries()" :key="index"
          >{{ viewData(data) }} <strong>({{ data.total_amount / 1000 + 'k' }})</strong><br
        /></span>
      </template>
      <template v-slot:cell(period_name)="item">
        {{ item.item.period.name }}
      </template>
      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(status)="item" :v-if="item.value == 1">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Đã In</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Chờ Xử Lý</b-badge>
        <b-badge class="mr-1" v-else variant="danger">Đã huỷ</b-badge>
      </template>
    </b-table>
    <!-- Footer -->
    <div style="display: flex; justify-content: space-between">
      <b-form inline style="margin-bottom: 1rem">
        <b-form-select
          id="per-page"
          v-model="perPage"
          size="sm"
          :options="[5, 10, 15, 20]"
          @change="fetchData"
        ></b-form-select>
      </b-form>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        align="right"
        v-on:input="fetchData"
      ></b-pagination>
    </div>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import datePicker from 'vue-bootstrap-datetimepicker';
import { formatDateForView } from '@/utils/time.js';
import LightBox from 'vue-image-lightbox';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
// Temporary hard code this
const CUSTOM = {
  big: 'Lớn',
  small: 'Nhỏ',
  even: 'Chẵn',
  odd: 'Lẻ',
  draw_big_small: 'Hoà (L-N)',
  draw_even_odd: 'Hoà (C-L)',
  even11: 'Chẵn (11-12',
  odd11: 'Lẻ (11-12)'
};
export default {
  name: 'Tickets',
  components: { LightBox },
  data() {
    return {
      isLoading: true,
      isFilterByGame: true,
      search: '',
      isBusy: false,
      filter_tsn: '',
      filter_period: '',
      filter_game: null,
      filter_status: null,
      filter_from: '',
      filter_to: '',
      statusList: [
        { value: 1, text: 'Printed', variant: 'success' },
        { value: 2, text: 'Canceled', variant: 'danger' },
        { value: 0, text: 'Pending', variant: 'warning' }
      ],
      headers: [
        { label: 'STT', key: 'priority' },
        { label: 'ID', key: 'id' },
        { label: 'Kỳ', key: 'period_no' },
        { label: 'Thời Gian', key: 'lottery_time' },
        { label: 'Hình', key: 'image_preview' },
        { label: 'Bộ Số', key: 'data' },
        { label: 'Trạng Thái', key: 'status', class: 'text-center' }
      ],
      // Modal data
      items: [],
      filterEmptyBackImage: false,
      selectedGame: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        barcode: '',
        created_at: '',
        dispatched_at: '',
        display_ticket_id: '',
        full_serial: '',
        status: '',
        image_url: '',
        last_four_serial: '',
        notice: '',
        printed_at: '',
        lottery_time: '',
        name: '',
        period_id: '',
        ticket_name: '',
        period_name: '',
        price: '',
        game: {
          name: ''
        },
        product: {
          name: ''
        }
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      viewItems: [],
      allGameAdsList: [],
      timeline: [
        {
          time: '16:00',
          icon: 'fa fa-genderless kt-font-brand',
          text: `Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
            <br>
            incididunt ut labore et dolore magna elit enim at minim<br>veniam quis nostrud`
        },
        {
          time: '17:00',
          icon: 'fa fa-genderless kt-font-danger',
          text: `Received a new feedback on 
            <a href="#" class="kt-link kt-link--brand kt-font-bolder">FinancePro App</a> product.`
        },
        {
          time: '15:30',
          icon: 'fa fa-genderless kt-font-danger',
          text: `New notification message has been received on 
            <a href="#" class="kt-link kt-link--brand kt-font-bolder">LoopFin Pro</a> product.`
        }
      ],
      cancelDialog: false,
      cancelReason: '',
      selectedTicketId: 0,
      ticket_id: null,
      filter_instant_status: null,
      instantStatusList: [
        { value: 4, text: 'All Instant Tickets' },
        { value: 0, text: 'Tickets without email' },
        { value: 1, text: 'Send mail successfully' },
        { value: 2, text: 'Send mail failed' },
        { value: 3, text: 'Ticket pending' }
      ],
      opcLogs: [],
      mailLogs: [],
      opcVariant: {
        order: 'danger',
        'ticket-handling-result': 'primary',
        'ticket-image-mapping': 'success',
        'payout-reward-ticket': 'warning',
        'winning-ticket': 'info'
      },
      mailType: {
        1: { variant: 'success', text: 'Sent printed ticket mail' },
        2: { variant: 'warning', text: 'Sent success payout ticket mail' },
        3: { variant: 'danger', text: 'Sent refund ticket mail' }
      },
      mailStatus: {
        0: { variant: 'warning', text: 'PENDING' },
        1: { variant: 'success', text: 'SUCCESS' },
        2: { variant: 'danger', text: 'FAILED' }
      },
      isAutoRefresh: false,
      idInterval: null
    };
  },
  watch: {
    filterEmptyBackImage() {
      this.onFilterEmptyBackImage();
    },
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return !this.cancelReason || this.errors.has('cancelReason');
    },
    token() {
      return this.$route.query.token;
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Tickets' }]);
    this.toggleAutoRefresh();
  },
  methods: {
    onFilterEmptyBackImage: _.debounce(function () {
      this.fetchData();
    }, 500),
    onFilterTSN: _.debounce(function () {
      this.fetchData();
    }, 500),
    updateTicketMail() {
      ApiService.post(`/mails/${this.viewItems.order.id}`, { mail: this.viewItems.order.mail })
        .then(() => {
          this.$bvToast.toast(`Update success!`, {
            title: null,
            variant: 'success',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        });
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    viewData(data) {
      let ticketInfo = data.data;
      let text = ``;
      try {
        ticketInfo = JSON.parse(ticketInfo);
        text = ticketInfo.map((d) => (d < 10 ? '0' + d : d)).join('_');
      } catch {
        text = CUSTOM[ticketInfo];
      }
      return text;
    },
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsTicket = ApiService.get(
        'ticket-shops',
        `?token=${this.token}&limit=${limit}&offset=${offset}&noImg=${this.filterEmptyBackImage ? 1 : 0}`
      );
      axios
        .all([adsTicket])
        .then(
          axios.spread((...response) => {
            const ticketRes = response[0];
            this.items = ticketRes.data.data.ticketList.map((ticket) => ({
              ...ticket,
              image_preview: [{ thumb: ticket.image_url, src: ticket.image_url }],
              data: JSON.parse(ticket.ticket_shop.metadata)
            }));
            this.rows = ticketRes.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    resendTicketMail: function (mailType) {
      ApiService.post(`/mails/${this.viewItems.id}/resends`, { type: mailType })
        .then(() => {
          this.$bvToast.toast(`Resend success!`, {
            title: null,
            variant: 'success',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        });
    },
    viewItem: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.isLoading = true;
      ApiService.get('tickets', `${this.editedItem.id}`)
        .then((resp) => {
          if (resp.status === 200) {
            this.viewItems = resp.data.data;
            this.opcLogs = this.viewItems.opc_logs.map(({ created_at, pattern, data }) => ({
              time: formatDateForView(created_at),
              icon: `fa fa-genderless kt-font-${this.opcVariant[pattern]}`,
              text: this.getOpcLogText(pattern, JSON.parse(data), this.viewItems.id)
            }));
            this.mailLogs = this.viewItems.mail_logs.map(({ mail_type, status, resend_count, created_at }) => ({
              time: formatDateForView(created_at),
              icon: `fa fa-genderless kt-font-${this.mailType[mail_type].variant}`,
              text: this.getMailLogText(mail_type, status, resend_count),
              mail_type,
              status
            }));
            this.modalTitle = `Ticket ${this.viewItems.display_ticket_id}`;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`${err}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    getOpcLogText(pattern, data, ticketId) {
      let text = '';
      switch (pattern) {
        case 'order':
          text = 'Sent order to OPC';
          break;
        case 'ticket-handling-result':
          text = `Received ticket status: <span class="text-${data.status ? 'success' : 'danger'}">${
            data.status ? 'SUCCESS' : 'CANCELED'
          }</span>`;
          if (!data.status) {
            text += `<br />
                    <span class="text-danger">Message: ${data.message}</span>`;
          }
          break;
        case 'ticket-image-mapping':
          text = `Received image from OPC`;
          break;
        case 'payout-reward-ticket':
          text = `Received ticket payout status: <span class="text-${data.state == 1 ? 'warning' : 'danger'}">${
            data.status == 1 ? 'SUCCESS' : 'FAILED'
          }</span>
          <br />
          Amount: ${this.formatPrice(data.amount)}`;
          if (data.status == 0) {
            text += `<br />
                    <span class="text-danger">Message: ${data.message}</span>`;
          }
          break;
        case 'winning-ticket':
          text = `Sent reward info to OPC with amount <span class="text-info">${this.formatPrice(
            data.tickets.find((ticket) => ticket.id == ticketId).amount
          )}</span>`;
          break;
        default:
          break;
      }
      return text;
    },
    getMailLogText(mailType, status, resendCount) {
      let text = `${this.mailType[mailType].text}.
                  <br />
                  Status: <span class="text-${this.mailStatus[status].variant}">
                    ${this.mailStatus[status].text}
                    </span>`;
      if (resendCount) {
        text += `<br />Resend count: ${resendCount}`;
      }
      return text;
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        barcode: '',
        created_at: '',
        dispatched_at: '',
        display_ticket_id: '',
        full_serial: '',
        status: '',
        image_url: '',
        last_four_serial: '',
        notice: '',
        printed_at: '',
        lottery_time: '',
        name: '',
        period_id: '',
        ticket_name: '',
        period_name: '',
        price: '',
        game: {
          name: ''
        },
        product: {
          name: ''
        }
      };
      this.editedIndex = -1;
      this.selectedGame = null;
      this.filter_status = null;
      // this.viewItems = [];
    },
    openCancelDialog(ticket) {
      this.cancelDialog = true;
      this.selectedTicketId = ticket.id;
      this.cancelReason = '';
    },
    async cancelTicket(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      if (this.selectedTicketId && this.cancelReason) {
        await ApiService.post(`tickets/${this.selectedTicketId}/cancel-ticket`, {
          reason: this.cancelReason
        });
        this.closeCancelDialog();
        this.fetchData();
      }
    },
    closeCancelDialog() {
      this.cancelDialog = false;
      this.selectedTicketId = 0;
      this.cancelReason = '';
    },
    toggleAutoRefresh() {
      if (this.isAutoRefresh) {
        clearInterval(this.idInterval);
      } else {
        this.idInterval = setInterval(() => {
          this.fetchData();
        }, 5000);
      }
      this.isAutoRefresh = !this.isAutoRefresh;
    }
  }
};
</script>

<style lang="scss">
.reason-form input {
  width: 100%;
  display: block;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9aabff;
    outline: 0;
  }
}
</style>
